<template>

    <CRow>
        <CCol col>
            <CCard>
                <CCardHeader>
                    <strong>入荷登録</strong>
                </CCardHeader>
                <vue-confirm-dialog></vue-confirm-dialog>
                <CCardBody>

                  <CModal
                      title="Filter"
                      color="warning"
                      size="xl"
                      :show.sync="filterModal"
                  >
                      <ItemMastersList :currentKey="currentKey" :isAutoSearch="true" @select-item="selectItemMaster"></ItemMastersList>
                  </CModal>
                  <div v-if="loading">
                    <LoadingCompoment />
                  </div>
                    <CForm v-for="(stock,k) in stocks" :key="stock.toLocaleString()">
                        <CRow  sm="1">
                            <CCol sm="3">
                              <div style="display: flex;padding: 5px; border: 1px solid #d8dbe0; border-radius: 5px" class="mt-1">
                                <strong>商品</strong>: {{stock.item_master_name}}
                              </div>
                            </CCol>
                          <CCol sm="1">
                            <CButton
                                @click="() => handOpenModal(k)"
                            >
                              <img
                                  src="img/loupe.png"
                                  style="width: 15px"
                              />
                            </CButton>
                          </CCol>
                            <CCol sm="2">
                                <CSelect
                                    id="shelf"
                                    label="棚"
                                    v-model="stock.shelf_id"
                                    :options="shelves"
                                    placeholder="選択する"
                                    @change="(e) => selectChangeShelf(e, k)"
                                    horizontal
                                    :invalid-feedback="errors[k] && errors[k].shelf_id ? errors[k].shelf_id : ''"
                                    :is-valid="errors[k] && errors[k].shelf_id ? false : null"
                                >
                                </CSelect>
                            </CCol>
                            <CCol sm="2">
                                <CInput
                                    id="amount"
                                    label="個数"
                                    horizontal
                                    v-model="stock.amount"
                                    type="number"
                                    min="1"
                                    :invalid-feedback="errors[k] && errors[k].amount ? errors[k].amount : ''"
                                    :is-valid="errors[k] && errors[k].amount ? false : null"
                                />
                            </CCol>
                            <CCol sm="3">
                                <CInput
                                    id="arrivedat"
                                    label="入荷日"
                                    type="date"
                                    horizontal
                                    v-model="stock.arrived_at"
                                    :invalid-feedback="errors[k] && errors[k].arrived_at ? errors[k].arrived_at : ''"
                                    :is-valid="errors[k] && errors[k].arrived_at ? false : null"
                                />
                            </CCol>
                            <CCol sm="1">
                                <CButton color="danger"
                                         @click="removeColumn(k)"
                                         v-show="k || stocks.length > 1"
                                >-
                                </CButton>
                            </CCol>
                        </CRow>

                        <CRow>
                            <CButton color="primary"
                                     @click="addColumn()"
                                     v-show="k == (stocks.length - 1)"
                                     style="margin-left: 20px;"
                            >
                                +行を追加
                            </CButton>
                        </CRow>
                    </CForm>
                </CCardBody>
                <CCardFooter>
                    <CButton @click="handleConfirm" type="submit" color="success">
                        <CIcon name="cil-check-circle"/>
                        登録する
                    </CButton>
                </CCardFooter>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
import ResourceApi from '../../api/resourceApi'
import Vue from "vue";
import ItemMastersList from "@/views/item-masters/ItemMastersList";

const apiStocks = new ResourceApi('stocks');
const apiShelves = new ResourceApi('shelves');
export default {
  name: 'CreateStock',
  components: {ItemMastersList},
  data() {
      return {
        stocks: [
          {
            item_master_id: '',
            item_master_name: '',
            shelf_id: '',
            amount: '',
            arrived_at: new Date().toISOString().slice(0,10),
          },
        ],
        shelvesId: '',
        key: Math.random(),
        filterModal: false,
        currentKey: 0,
        shelves: [],
        items: [],
        confirmModal: false,
        loading: false,
        errors: {},
        itemMasters: [],
        // suggestions: [
        //   {
        //     data: []
        //   }
        // ]
      }
    },
  created() {
    this.getAllShelves();
  },
  // computed: {
  //   filteredOptions() {
  //     return [
  //       {
  //         data: this.suggestions[0].data.filter(option => {
  //           return option.name.indexOf(this.itemMaterName) > -1;
  //         })
  //       }
  //     ];
  //   }
  // },
  methods: {
      //
      // clickHandler(item) {
      //   // event fired when clicking on the input
      // },
      selectItemMaster({item, k}) {
          this.stocks[k].item_master_id = parseInt(item.id)
          this.stocks[k].item_master_name = item.name
          this.currentKey = null
          this.filterModal = false
      },

    handOpenModal(k){
      this.filterModal = true;
      this.currentKey = k;
    },

    focusMe(e) {
      console.log(e) // FocusEvent
    },


      addColumn(index) {
        let k = this.stocks.length
          this.stocks.push({item_master_id: '', shelf_id: '', amount: '', arrived_at: new Date().toISOString().slice(0,10)});
          this.currentKey = k;
      },

      removeColumn(index) {
          this.stocks.splice(index, 1);
      },


      getAllShelves() {
          this.loading = true
          apiShelves.all().then(response => {
              this.shelves = response.data.data;
              this.shelves.forEach(shelf => {
                  shelf.label = shelf.name
                  shelf.value = shelf.id
              })
          }).catch(error => {
          }).finally(() =>{
              this.loading = false
          })
      },

      selectChangeShelf(val, k) {
          this.stocks[k].shelf_id = parseInt(val.target.value)
      },


      goScreenList() {
          this.$router.back()
      },

      handleConfirm(){
          this.$confirm(
              {
                  title: '確認',
                  message: `入荷登録を行います。宜しいですか？`,
                  button: {
                      no: 'いいえ',
                      yes: 'はい'
                  },
                  callback: confirm => {
                      if (confirm) {
                          // ... do something
                          this.createStock()
                      }
                  }
              }
          )
      },
    changeShelves (val) {
      this.shelvesId = val? parseInt(val): ''
    },
    createStock() {
        this.loading = true
        let param = this.stocks;
        this.confirmModal = true;
        apiStocks.create(param).then(response => {
            Vue.$toast.success('登録成功しました。')
            setTimeout(this.goScreenList, 2000);
        }).catch(error => {
            let dataErrors = error.response?.data?.errors || ''
            let errors ={}
            Object.keys(dataErrors).map(function(key, index) {
                let messages = dataErrors[key][0];
                const keyArr = key.split('.');
                if (errors?.[keyArr[0]]) {
                    errors[keyArr[0]][keyArr[1]] = messages;
                } else {
                    errors[keyArr[0]] =  {[keyArr[1]]: messages};
                }
            });
            this.errors = errors;
            // console.log(11111, this.errors)
            // Vue.$toast.error('Validate error')
        }).finally(() =>{
            this.loading = false
        })
    }
  },
}
</script>

<style>
.breadcrumb-item + .font-xl.breadcrumb-item::before {
    color: rgb(140, 195, 38);
    content: '>>';
    padding: 0px 10px;

}
.form-over-size label{
    max-width: 30%;
    flex: 0 0 30%;
}
.form-over-size .col-sm-9{
    max-width: 70%;
    flex: 0 0 70%;
}
.error_item{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e55353;
}
#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  color: #768192;
  background-color: #fff;
  border-color: #d8dbe0;
  border-radius: 0.25rem;
}

#autosuggest__input.autosuggest__input-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: absolute;
  z-index: 10000001;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  padding: 0px;
  overflow-y: scroll;
  max-height: 300px;
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.autosuggest__results .autosuggest__results_item {
  cursor: pointer;
  padding: 15px;
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
  border-top: none;
}

/*.autosuggest__results .autosuggest__results_title {*/
/*  color: gray;*/
/*  font-size: 11px;*/
/*  margin-left: 0;*/
/*  padding: 15px 13px 5px;*/
/*  border-top: 1px solid lightgray;*/
/*}*/
.item-master{
  padding: 5px;
}
.item-master:hover{
  background: gray;
  color: white;
  cursor: pointer;
}

/*.autosuggest__results .autosuggest__results_item:active,*/
/*.autosuggest__results .autosuggest__results_item:hover,*/
/*.autosuggest__results .autosuggest__results_item:focus,*/
/*.autosuggest__results .autosuggest__results_item.autosuggest__results_item-highlighted {*/
/*  background-color: #ddd;*/
/*}*/
</style>
